// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllVendorData = createAsyncThunk('appVendors/getAllData', async () => {
  const response = await axios.get('/api/vendors/list/all-data')
  return response.data
})

export const getVendorData = createAsyncThunk('appVendors/getData', async params => {
  const response = await axios.get('/api/vendors/list/data', { 'params': params })
  console.log(response.data)
  return {
    params,
    data: response.data.vendors,
    totalPages: response.data.total
  }
})

// export const getOptions = createAsyncThunk('appUsers/getOptions', async params => {
//   const response = await axios.get('/api/assets/list/getOptions', params)
//   return {
//     params,
//     typeOptions: response.data.data
//   }
// })

// export const getUser = createAsyncThunk('appUsers/getAsset', async id => {
//   const response = await axios.get(`/api/assets/asset?id=${id}`)
//   return response.data
// })

// export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
//   await axios.post('/apps/users/add-user', user)
//   await dispatch(getData(getState().users.params))
//   await dispatch(getAllData())
//   return user
// })

// export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/users/delete', { id })
//   await dispatch(getData(getState().users.params))
//   await dispatch(getAllData())
//   return id
// })

export const appVendorsSlice = createSlice({
  name: 'appVendors',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: 'pending',
    typeOptions: [{ value: '', label: 'Select Type' }],
    locationOptions: [{ value: '', label: 'Select Location' }],
    categoryOptions: [{ value: '', label: 'Select Category' }],
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllVendorData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getVendorData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appVendorsSlice.reducer
