// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/assets/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('/api/assets/list/data', {'params':params})
  console.log(response.data)
  return {
    params,
    data: response.data.assets,
    totalPages: response.data.total
  }
})

// export const getOptions = createAsyncThunk('appUsers/getOptions', async params => {
//   const response = await axios.get('/api/assets/list/getOptions', params)
//   return {
//     params,
//     typeOptions: response.data.data
//   }
// })

export const getUser = createAsyncThunk('appUsers/getAsset', async id => {
  const response = await axios.get(`/api/assets/asset?id=${id}`)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: 'pending',
    typeOptions: [{value: '', label: 'Select Type'}]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      // .addCase(getOptions.fulfilled, (state, action) => {
      //   state.typeOptions = action.payload.typeOptions
      // })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = 'fulfilled'
      })
  }
})

export default appUsersSlice.reducer
