// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAssets = createAsyncThunk('appAssets/getAssets', async category => {
  const response = await axios.get(`/apps/assets-breakdown/assets?category=${category.id}`)
  return {
    data: response.data
  }
})

export const appAssetSlice = createSlice({
  name: 'appAssets',
  initialState: {
    assets: [],
    loading: 'loading'
  },
  reducers: {},

  extraReducers: builder => {
        builder
          .addCase(getAssets.fulfilled, (state, action) => {
            state.assets = action.payload
            state.loading = 'fulfilled'
          })
        }})

export default appAssetSlice.reducer