// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appSensor/getAllData', async () => {
    const response = await axios.get('/api/getSensorChartData?historical=true')
    return response.data
})

export const getDailyData = createAsyncThunk('appSensor/getDailyData', async () => {
    const response = await axios.get('/api/getSensorChartData?historical=false')
    return response.data
})

export const getData = createAsyncThunk('appSensor/getData', async params => {
    const response = await axios.get('/api/sensors/list/data', { 'params': params })
    return {
        params,
        data: response.data.sensors,
        totalPages: response.data.total
    }
})

export const getSensor = createAsyncThunk('appSensor/getSensor', async id => {
    const response = await axios.get(`/api/sensors/sensor?id=${id}`)
    return response.data
})

export const appSensorSlice = createSlice({
    name: 'appSensor',
    initialState: {
        data: [],
        allData: [],
        dailyData: [],
        loading: 'pending',
        params: {},
        total: 1,
        typeOptions: [{ value: '', label: 'Select Type' }],
        buildingOptions: [{ value: '', label: 'Select Building' }],
        zoneOptions: [{ value: '', label: 'Select Zone' }],
        locationOptions: [{ value: '', label: 'Select Location' }],
        selectedUser: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAllData.fulfilled, (state, action) => {
                state.allData = action.payload
            })
            .addCase(getDailyData.fulfilled, (state, action) => {
                state.dailyData = action.payload
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getSensor.fulfilled, (state, action) => {
                state.selectedUser = action.payload
                state.loading = 'fulfilled'
            })
    }
})

export default appSensorSlice.reducer
